<template>
  <!--      Start  Form -->
  <v-dialog
    v-if="entity"
    v-model="open"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar flat>
        <v-btn icon :loading="processing" :disabled="processing" @click="close">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title
          >{{
            $t("model.edr_colleage.edr_colleage") +
              " (" +
              (!editMode ? $t("base.addNew") : $t("base.edit")) +
              ")"
          }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <validation-observer ref="form" v-slot="{ handleSubmit, reset }">
          <form @submit.prevent="handleSubmit(onSave)" @reset.prevent="reset">
            <v-container>
              <v-row>
                <!-- //Row  -->
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.name_th')"
                    rules="max:255"
                  >
                    <v-text-field
                      v-model="entity.name_th"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.name_th')"
                      :label="$t('model.edr_colleage.name_th')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.name_en')"
                    rules="max:255"
                  >
                    <v-text-field
                      v-model="entity.name_en"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.name_en')"
                      :label="$t('model.edr_colleage.name_en')"
                     />
                  </validation-provider>
                </v-col>
                <!-- /Row  -->

                <!-- Row  -->
                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.school_id')"
                    rules="max:20"
                  >
                    <v-text-field
                      v-model="entity.school_id"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.school_id')"
                      :label="$t('model.edr_colleage.school_id')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.college_code')"
                    rules="max:25"
                  >
                    <v-text-field
                      v-model="entity.college_code"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.college_code')"
                      :label="$t('model.edr_colleage.college_code')"
                     />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'College Website'"
                    rules="max:125"
                  >
                    <v-text-field
                      v-model="entity.college_site"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="'College Website'"
                      :label="'College Website'"
                     />
                  </validation-provider>
                </v-col>
                <!-- End  -->

                <!-- //Row  -->
                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.ipaddress')"
                    rules="max:50"
                  >
                    <v-text-field
                      v-model="entity.ipaddress"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.ipaddress')"
                      :label="$t('model.edr_colleage.ipaddress')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.domain')"
                    rules="max:100"
                  >
                    <v-text-field
                      v-model="entity.domain"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.domain')"
                      :label="$t('model.edr_colleage.domain')"
                     />
                  </validation-provider>
                </v-col>

                <!-- //Row  -->
                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.java_ipaddress')"
                    rules="max:180"
                  >
                    <v-text-field
                      v-model="entity.java_ipaddress"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.java_ipaddress')"
                      :label="$t('model.edr_colleage.java_ipaddress')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.php_ipaddress')"
                    rules="max:180"
                  >
                    <v-text-field
                      v-model="entity.php_ipaddress"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.php_ipaddress')"
                      :label="$t('model.edr_colleage.php_ipaddress')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.sockets_path')"
                    rules="max:180"
                  >
                    <v-text-field
                      v-model="entity.sockets_path"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.sockets_path')"
                      :label="$t('model.edr_colleage.sockets_path')"
                     />
                  </validation-provider>
                </v-col>

                <!-- //Row  -->
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="entity.use_std_api"
                    :label="
                      entity.use_std_api
                        ? $t('model.edr_colleage.use_std_api')
                        : $t('model.edr_colleage.use_std_api')
                    "
                   />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="entity.status"
                    :label="
                      entity.status ? $t('base.enable') : $t('base.disable')
                    "
                   />
                </v-col>

                <v-col cols="12" sm="4">
                  <p class="caption">
                    {{ $t("model.edr_colleage.ssl_expire") }}
                  </p>
                  <v-date-picker v-model="entity.ssl_expire" />
                </v-col>

                <!-- //Row  -->
                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.storage')"
                    rules="numeric|max:11"
                  >
                    <v-text-field
                      v-model="entity.storage"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.storage')"
                      :label="$t('model.edr_colleage.storage')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.ram')"
                    rules="numeric|max:11"
                  >
                    <v-text-field
                      v-model="entity.ram"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.ram')"
                      :label="$t('model.edr_colleage.ram')"
                     />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('model.edr_colleage.cpu')"
                    rules="numeric|max:11"
                  >
                    <v-text-field
                      v-model="entity.cpu"
                      prepend-icon="mdi-pencil"
                      :error-messages="errors"
                      :placeholder="$t('model.edr_colleage.cpu')"
                      :label="$t('model.edr_colleage.cpu')"
                     />
                  </validation-provider>
                </v-col>

                <!-- //Row  -->
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="entity.deploy_java"
                    label="Deploy Java"
                   />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="entity.deploy_php"
                    label="Deploy Php"
                   />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-switch
                    v-model="entity.production"
                    label="Production"
                   />
                </v-col>

                <v-col cols="12" class="mt-6" align="center">
                  <v-btn text :disabled="processing" @click="close">
                    {{ $t("base.cancel") }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    text
                    color="primary"
                    :disabled="processing"
                  >
                    <v-icon>mdi-lead-pencil</v-icon> {{ $t("base.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";
export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: null,
    open: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const entity = reactive(props.value);
    const close = () => {
      emit("close");
    };
    const onSave = () => {
      emit("save", entity);
    };
    return {
      entity,
      close,
      onSave
    };
  }
});
</script>
