var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entity)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":"","loading":_vm.processing,"disabled":_vm.processing},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-keyboard-backspace")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("model.edr_colleage.edr_colleage") + " (" + (!_vm.editMode ? _vm.$t("base.addNew") : _vm.$t("base.edit")) + ")")+" ")]),_c('v-spacer')],1),_c('v-card-text',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSave)},"reset":function($event){$event.preventDefault();return reset($event)}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.name_th'),"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.name_th'),"label":_vm.$t('model.edr_colleage.name_th')},model:{value:(_vm.entity.name_th),callback:function ($$v) {_vm.$set(_vm.entity, "name_th", $$v)},expression:"entity.name_th"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.name_en'),"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.name_en'),"label":_vm.$t('model.edr_colleage.name_en')},model:{value:(_vm.entity.name_en),callback:function ($$v) {_vm.$set(_vm.entity, "name_en", $$v)},expression:"entity.name_en"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.school_id'),"rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.school_id'),"label":_vm.$t('model.edr_colleage.school_id')},model:{value:(_vm.entity.school_id),callback:function ($$v) {_vm.$set(_vm.entity, "school_id", $$v)},expression:"entity.school_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.college_code'),"rules":"max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.college_code'),"label":_vm.$t('model.edr_colleage.college_code')},model:{value:(_vm.entity.college_code),callback:function ($$v) {_vm.$set(_vm.entity, "college_code", $$v)},expression:"entity.college_code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":'College Website',"rules":"max:125"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":'College Website',"label":'College Website'},model:{value:(_vm.entity.college_site),callback:function ($$v) {_vm.$set(_vm.entity, "college_site", $$v)},expression:"entity.college_site"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.ipaddress'),"rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.ipaddress'),"label":_vm.$t('model.edr_colleage.ipaddress')},model:{value:(_vm.entity.ipaddress),callback:function ($$v) {_vm.$set(_vm.entity, "ipaddress", $$v)},expression:"entity.ipaddress"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.domain'),"rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.domain'),"label":_vm.$t('model.edr_colleage.domain')},model:{value:(_vm.entity.domain),callback:function ($$v) {_vm.$set(_vm.entity, "domain", $$v)},expression:"entity.domain"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.java_ipaddress'),"rules":"max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.java_ipaddress'),"label":_vm.$t('model.edr_colleage.java_ipaddress')},model:{value:(_vm.entity.java_ipaddress),callback:function ($$v) {_vm.$set(_vm.entity, "java_ipaddress", $$v)},expression:"entity.java_ipaddress"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.php_ipaddress'),"rules":"max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.php_ipaddress'),"label":_vm.$t('model.edr_colleage.php_ipaddress')},model:{value:(_vm.entity.php_ipaddress),callback:function ($$v) {_vm.$set(_vm.entity, "php_ipaddress", $$v)},expression:"entity.php_ipaddress"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.sockets_path'),"rules":"max:180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.sockets_path'),"label":_vm.$t('model.edr_colleage.sockets_path')},model:{value:(_vm.entity.sockets_path),callback:function ($$v) {_vm.$set(_vm.entity, "sockets_path", $$v)},expression:"entity.sockets_path"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":_vm.entity.use_std_api
                      ? _vm.$t('model.edr_colleage.use_std_api')
                      : _vm.$t('model.edr_colleage.use_std_api')},model:{value:(_vm.entity.use_std_api),callback:function ($$v) {_vm.$set(_vm.entity, "use_std_api", $$v)},expression:"entity.use_std_api"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":_vm.entity.status ? _vm.$t('base.enable') : _vm.$t('base.disable')},model:{value:(_vm.entity.status),callback:function ($$v) {_vm.$set(_vm.entity, "status", $$v)},expression:"entity.status"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("model.edr_colleage.ssl_expire"))+" ")]),_c('v-date-picker',{model:{value:(_vm.entity.ssl_expire),callback:function ($$v) {_vm.$set(_vm.entity, "ssl_expire", $$v)},expression:"entity.ssl_expire"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.storage'),"rules":"numeric|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.storage'),"label":_vm.$t('model.edr_colleage.storage')},model:{value:(_vm.entity.storage),callback:function ($$v) {_vm.$set(_vm.entity, "storage", $$v)},expression:"entity.storage"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.ram'),"rules":"numeric|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.ram'),"label":_vm.$t('model.edr_colleage.ram')},model:{value:(_vm.entity.ram),callback:function ($$v) {_vm.$set(_vm.entity, "ram", $$v)},expression:"entity.ram"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('model.edr_colleage.cpu'),"rules":"numeric|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"mdi-pencil","error-messages":errors,"placeholder":_vm.$t('model.edr_colleage.cpu'),"label":_vm.$t('model.edr_colleage.cpu')},model:{value:(_vm.entity.cpu),callback:function ($$v) {_vm.$set(_vm.entity, "cpu", $$v)},expression:"entity.cpu"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Deploy Java"},model:{value:(_vm.entity.deploy_java),callback:function ($$v) {_vm.$set(_vm.entity, "deploy_java", $$v)},expression:"entity.deploy_java"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Deploy Php"},model:{value:(_vm.entity.deploy_php),callback:function ($$v) {_vm.$set(_vm.entity, "deploy_php", $$v)},expression:"entity.deploy_php"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Production"},model:{value:(_vm.entity.production),callback:function ($$v) {_vm.$set(_vm.entity, "production", $$v)},expression:"entity.production"}})],1),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.processing},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("base.cancel"))+" ")]),_c('v-btn',{attrs:{"type":"submit","text":"","color":"primary","disabled":_vm.processing}},[_c('v-icon',[_vm._v("mdi-lead-pencil")]),_vm._v(" "+_vm._s(_vm.$t("base.save"))+" ")],1)],1)],1)],1)],1)]}}],null,false,1748304752)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }